.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

@media (max-width: 768px) {
  .chat-container {
    max-width: 100%;
  }
}

.messages-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: #f9f9f9;
}

.message {
  padding: 5px 10px;
  margin: 0px 0;
}

.your-message {
  background: #e1ffc7be;
}

.stranger-message {
  background: #ffb5b062;
}

.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background: #fff;
}

.input-container textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: inherit;
  font-size: inherit; 
}

.input-container button {
  flex: 0 1 100px;
  margin-left: 10px;
  padding: 10px;
  border: none;
  background: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.input-container button:hover {
  background: #0056b3;
}