.header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #e7f3ff71;
    color: #000000d3;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}

.header-logo {
    height: 40px;
    margin: 10px 20px 10px 20px;
}

.header-title {
    font-size: 1.5em;
    margin: 0;
}

.header-slogan {
    font-size: 14px;
    color: #666;
    margin: 0;
}

.header-texts {
    display: flex;
    flex-direction: column; /* Garante que o título e slogan fiquem em coluna */
    align-items: left;
  }